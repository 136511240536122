<template>
  <div>
    <b-modal
      ref="share-good-modal"
      hide-footer
      :titleHtml="modalTitle"
      size="xl"
    >
      <b-row>
        <b-col>
          <b-table
            :items="poProducts"
            :fields="fields"
            bordered
            hover
            show-empty
          >
            <template #empty="scope">
              <h5 class="text-center">Không có sản phẩm nào được gợi ý</h5>
            </template>
            <template v-slot:cell(quantity)="row">
              <b-input
                type="text"
                size="sm"
                class="input-style text-right"
                v-model="row.item.quantity"
                :disabled="!editable"
              ></b-input>
            </template>
          </b-table>
          <b-button
            v-if="editable"
            style="fontweight: 600; width: 100px; margin-right: 10px"
            variant="primary"
            size="sm"
            @click="onSubmit"
            >Lưu</b-button
          >
          <b-button
            style="font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
            @click="hideModal"
          >
            Hủy
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { cloneDeep, unMaskPrice } from '../../../utils/common';
export default {
  props: ['editable'],
  data() {
    return {
      modalTitle: '',
      poProducts: [],
      fields: [
        {
          key: 'barCode',
          label: 'Mã vạch',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
          thStyle: { fontWeight: 600, color: '#181c32', width: '30%' },
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          thStyle: { fontWeight: 600, color: '#181c32', width: '40%' },
        },
        {
          key: 'quantity',
          label: 'Số lượng',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
      ],
      inventoryId: null,
    };
  },
  methods: {
    showModal: function (storeId, storeName, products) {
      this.searchText = '';
      this.inventoryId = storeId;
      this.poProducts = cloneDeep(products);
      this.modalTitle = `<div>
              <span class='d-block' style='font-size: 1.1rem'>Chia hàng cho kho: <a class='text-primary'>#${storeName}</a></span>
              </div>`;
      this.$refs['share-good-modal'].show();
    },
    hideModal: function () {
      this.$refs['share-good-modal'].hide();
    },
    onSubmit() {
      const transformedProducts = this.poProducts.map((product) => {
        const unMaskQuantity = unMaskPrice(product.quantity);
        return {
          ...product,
          quantity: parseInt(unMaskQuantity),
        };
      });
      const result = {
        inventoryId: this.inventoryId,
        products: transformedProducts,
      };
      this.$emit('onSubmit', result);
      this.hideModal();
    },
  },
};
</script>
